import {api} from './client';
import {dataUrlToFile} from '@src/utils/photoUtils';

export namespace ImagesApi {
  export enum UserPictureType {
    USER_AVATAR = 'USER_AVATAR',
    USER_OTHER = 'USER_OTHER',
  }

  export const addUserPicture = async (
    file: File,
    order?: string,
    type?: UserPictureType
  ) => {
    const form = new FormData();
    form.append('image', file);
    if (order !== undefined) {
      form.append('order', order);
    }
    if (type !== undefined) {
      form.append('type', type);
    }
    const res = await api.post('users/images', form);
    return res;
  };

  export const addUserPictureNoResponse = async (
    file: File,
    order?: string,
    type?: UserPictureType,
    userId?: string
  ) => {
    const form = new FormData();
    form.append('image', file);
    if (order !== undefined) {
      form.append('order', order);
    }
    if (type !== undefined) {
      form.append('type', type.toString());
    }
    if (userId) {
      form.append('userId', userId);
    }
    await api.post('users/images', form);
  };

  export const deleteUserPicture = async (fileId: string, userId?: string) => {
    const res = await api.delete(`users/images/${fileId}`, {
      params: {
        userId: userId || undefined,
      },
    });
    return res.data;
  };

  export const addClubPicture = async (
    file: File,
    order: string | null,
    clubId: string
  ) => {
    const form = new FormData();
    form.append('image', file);
    /**99 order means it's a profile pic (logo) - so we skip it in form Data */
    if (order !== null && order !== '99') {
      form.append('order', order);
    }
    const res = await api.post(`companies/${clubId}/images`, form);
    return res.data;
  };
  export const getImage = async (imageId: string) => {
    const res = await api.get(`cdn/files/${imageId}`, {
      responseType: 'blob',
    });
    return res.data;
  };
  export const deleteImage = async (imageId: string) => {
    const res = await api.delete(`cdn/delete/${imageId}`);
    console.log(res);
    return res.data;
  };

  export const deleteCompanyImage = async (
    imageId: string,
    companyId: string
  ) => {
    const res = await api.delete(`companies/${companyId}/images/${imageId}`);
    return res.data;
  };

  export const addTopicImage = async (discussionId: string, file: File) => {
    const form = new FormData();
    form.append('file', file);
    form.append('type', 'DISCUSSION_AVATAR');
    form.append('visibleToAll', 'true');
    form.append('discussionId', discussionId);
    await api.post('cdn/upload', form);
  };

  export const addAnswerImage = async (
    file: string,
    id: string,
    lang: string
  ) => {
    const pic = await dataUrlToFile(file, 'profile-pic');
    const formData = new FormData();
    formData.append('file', pic);
    formData.append('type', 'QUESTION_ANSWER_AVATAR');
    formData.append('visibleToAll', 'true');
    formData.append('questionAnswerId', id);
    formData.append('language', lang);
    await api.post('cdn/upload', formData);
  };
}
