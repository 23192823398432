import {Crop} from 'react-image-crop';

export const getBase64SizeInKb = async (photo: string) => {
  try {
    const file = await dataUrlToFile(photo, 'pic');
    return file.size;
  } catch (e) {
    return 99999999;
  }
};
export const getBase64Extension = (photo: string) => {
  return photo.substring('data:image/'.length, photo.indexOf(';base64'));
};

export async function createFileFromHTMLImageElementAndCrop(
  image: HTMLImageElement,
  crop: Crop,
  orgImg: File
): Promise<{
  file: File | null;
  preview: string | unknown;
} | null> {
  try {
    const canvas = new OffscreenCanvas(crop.width, crop.height);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    const blob = await canvas.convertToBlob();

    const file = new File([blob], orgImg?.name ?? '', {type: 'image/jpg'});
    const base64 = await toBase64(file);

    return {file: file, preview: base64};
  } catch (e) {
    console.error('ImageService::createFileFromHTMLImageElementAndCrop:', e);
    return null;
  }
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, {type: 'image/png'});
}

export function getFileExtension(filename: string) {
  return filename.split('.').pop();
}
